@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body{
  font-family: 'Open Sans', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

select option {
  color: black;
}

.table-input {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 10px;
  width: 30px;
  color: rgb(0, 0, 0);
}

.w-80 {
  max-width: 80vw;
}

.fz-11px{
  font-size: 11px;
}
.m-auto {
  margin: auto;
}

/* .myButton {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: white;
} */

.myButton:hover {
  text-decoration: underline;
}

.priceInput {
  padding: 5px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  margin: 10px;
}

.mySelect {
  padding: 0.5rem 2rem;
}

.pagination-btn {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  margin: 5px 5px;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #007bff;
  color: #fff;
}

.cont-grey {
  background-color: rgba(0, 0, 0, 0.18);
}

.buttonBG {
  background-color: rgba(255, 255, 255, 0.1);
}

.customCard {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px 12px;
  gap: 8px;
  margin: 1rem;
  width: 210.8px;

  background: rgba(255, 255, 255, 0.1);
  border-left: 6px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  z-index: 0;
}

/* .card{
  background-color: #343a40!important;
  color: #fff !important;
} */

.nav-link {
  color: white !important;
}

.content-wrapper {
  background-color: #343a40 !important;
  color: #fff !important;
}

.card {
  background-color: #1818184a !important;
  color: #fff !important;
  margin-bottom: 0;
}

body {
  overflow-x: hidden;
  background-color: #343a40 !important;
}

.pagination-btn {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 4px !important;
  color: rgb(131, 131, 131) !important;
}
.pagination-btn.active {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background-color: rgb(0 0 0 / 47%) !important;
  border-radius: 4px !important;
  color: rgb(131, 131, 131) !important;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #292929;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7f7f7f;
}

.thumbnail_Image:hover {
  border-radius: 12px;
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.table td{
  vertical-align: middle;
}

.w-85{
  width: 85vw !important;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.loader-div {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #33333378;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  z-index: 9999999;
}

.loader {
	position: relative;
	width: 10vw;
	height: 5vw;
	padding: 1.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader span {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	border-radius: 50%;
	background-color: #ff0;
}

.loader span:nth-child(1) {
	animation: loading-dotsA 0.5s infinite linear;
}

.loader span:nth-child(2) {
	animation: loading-dotsB 0.5s infinite linear;
}

@keyframes loading-dotsA {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(2vw);
	}
	100% {
		transform: none;
	}
}

@keyframes loading-dotsB {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(-2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(-2vw);
	}
	100% {
		transform: none;
	}
}
